.MobileContainer{
    width: 100vw;
    height: 100vh;
    background-color: var(--light_base);
    overflow-y: hidden;
    overflow-x: hidden;
}

.mobile-home {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--light_orange);
    height: 85vw;
    width: 85vw;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mobile-screen{
    margin-top: -24px;
    height: 50vw;
    width: 50vw;
    object-fit: contain;
}

.mobile-text{
    width: 60vw;
    font: 600 16px 'Lato';
    text-align: center;
    margin: 0;
    margin-top: -16px;
}

.RotateContainer{
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
}

.rotate-container{
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 60vw;
    width: 60vw;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    } 40%, 100% {
        transform: rotate(90deg);
    }
    
}
.rotate-box{
    border: 5px solid;
    width: 15vw;
    height: 25vw;
    border-radius: 15px;
    position: relative;
    animation: rotate 5s ease-in-out infinite
}

.arrow-1{
    width: 4vw;
    object-fit: contain;
    position: absolute;
    bottom: -5vw;
    left: -5vw;
}

.arrow-2{
    width: 4vw;
    object-fit: contain;
    position: absolute;
    top: -5vw;
    right: -5vw;
    transform: rotate(-180deg);
}

.rotate-text{
    margin-top: 5vw;
    font: 400 2.5vmin 'Lato';
    width: 40vw;
    text-align: center;
}