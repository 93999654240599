.lightToggleContainer {
    width: 3.15rem;
    height: 1.5rem;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    position: relative;
    transition: background-color 1.5s;
}

.toggleButton{
    width: 1.3rem;
    height:1.3rem;
    border-radius: 50%;
    position: absolute;
    left: 1.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 1.5s;
    animation: none;
    z-index: 1
}

.lightToggleContainer:hover{
    cursor: pointer;
}

.toggleEyes{
    background-color: var(--dark_base);
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    animation: none
}


@keyframes togglemoveleft {
    0%{
        left: 1.7rem;

    } 100% {
        left: 0.15rem;
    }
}

@keyframes togglemoveright {
    0%{
        left: 0.15rem;

    } 100% {
        left: 1.7rem;
    }
}

@keyframes eyeSlit {
    0%{
        transform: scaleX(1);

    } 100% {
        transform: scaleX(0.3);
    }
}

@keyframes eyeWiden {
    0%{
        transform: scaleX(0.3);

    } 100% {
        transform: scaleX(1);
    }
}

.moon{
    width: 1rem ;
    height: 1rem ;
    background-color: transparent ; 
    box-shadow: inset 0.4rem -0.2rem var(--dark_base) ;
    border-radius: 0.5rem ;
    position: absolute;
    right: 0.2rem;
    transition: opacity 1s;
}

.sun{
    color: var(--light_base);
    width: 1.1rem;
    height: 1.1rem;
    fill: var(--dark_base);
    position: absolute;
    left: 0.2rem;
    transition: opacity 0.25s;
}

