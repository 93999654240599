.logoCircleHome{
    position: relative;
    min-width:30vw;
    height: 30vw;
    border-radius: 50%;
    background-color: var(--light_orange);
    margin: calc(30vh - 18vw) 35%;
    transform-origin: center;
    justify-content: center;
    align-items: center;
    display: flex;
    animation: slideleft 2s ease-in-out forwards;
    transition: background-color 2.5s;
    transition-delay: 0.1s;
}


@keyframes slideleft {

    0% {
        margin: calc(30vh - 18vw + 16px) 35%;
        transform: scale(1);
    }
    15%{
        margin: calc(30vh - 18vw + 16px) 35%;
        transform: scale(1.1);
    }
    40%{
        margin: calc(30vh - 18vw + 16px) 35%;
        transform: scale(0.8);
    }

    100% {
        margin: calc(30vh - 18vw + 16px) 5%;
        transform: scale(0.8);
    }
    
}

.welcometext{
    position: absolute;
    left: 40vw;
    opacity: 0;
    animation: textslidein 1s forwards 1.4s;
}

@keyframes textslidein {

    0%{
        opacity: 0;
        transform: translateX(25%);
    }

    100%{
        opacity: 1;
        transform: translateX(0);
    }
    
}

.eyesHome{
    background-color: white;
    position: absolute;
    left:11vw;
    z-index: 999;
    width: 5vw;
    height: 2.5vw;
    border-radius: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    transition: background-color 0.5s;
    animation: blinking 6s ease-in-out infinite 5s; 
}

.eyesHome:hover{
    cursor: pointer;
}

.pupilsHome{
    background-color: var(--dark_base);
    width: 2.6vw;
    min-height: 2.6vw;
    border-radius: 50%;
    position: absolute;
    transform-origin: center;
    left: 18px;
    top: -1px;
    transition: left 0.2s ease, top 0.2s ease;
    animation: none
}

@keyframes blinking {

    0%, 20% {
        height: 2.5vw;
    }
    10% {
        height: 0.2vw;
    }
}


@keyframes narrowingeyes {
    0%, 100% {
        height: 2.5vw;
    }
    20%, 80% {
        height: 0.5vw;
    }
}

@keyframes sliteyes {
    0% {
        transform: scaleX(1);
        top:-1px;
        left:18px
    }
    20%{
        transform: scaleX(1);
        top:-15px;
        left:18px;
    }
    80%{
        top:-15px;
        left:18px;
    }
    80%, 100% {
        transform: scaleX(0.3);
        top:-1px;
        left:18px;
    }
}

@keyframes openeyes {
    0% {
        transform: scaleX(0.3);
        top:-1px;
        left:18px
    }
    20%{
        transform: scaleX(0.3);
        top:-15px;
        left:18px;
    }
    80%{
        top:-15px;
        left:18px;
    }
    80%, 100% {
        transform: scaleX(1);
        top:-1px;
        left:18px;
    }
}

.flexcontainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 100vh;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
}

.info{
    font-family: 'Lato', sans-serif;
    font-size: 4.5vmin;
    width: 50vw;
    margin: 12px 8px;
    padding: 0;
}

.info2{
    font-family: 'Lato', sans-serif;
    font-size: 4.5vmin;
    width: 50vw;
    margin: 8px;
    padding: 0;
}

.info3{
    font-family: 'Lato', sans-serif;
    font-size:7vmin;
    width: 50vw;
    margin: 8px 8px 16px 8px;
    padding: 0;
}

.arrow{
    font-family: 'Lato', sans-serif;
    font-size: 5vmin;
    margin: 0 ;
    padding: 0;
}

.getintouch{
    font-family: 'Lato', sans-serif;
    text-decoration: underline;
    text-underline-offset: 0.15em;
    text-decoration-thickness: 2px;
    font-size: 4vmin;
    cursor: pointer;
}

.outline{
    color: transparent;
    font-weight: 900;
    -webkit-text-stroke-width: 1.5px;
    transition: -webkit-text-stroke-color 1.5s;
    transition-delay: 0.1s;
}

.externalIcon{
    font-size: 6vmin;
    margin: 1vh 1vw 0 1vw;
    transition: color 1.5s;
    transition-delay: 0.1s;
}

.iconContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.righticonContainer{
    display: flex;
    flex-direction: row;
    margin-right: 0vw;
}

.contactbutton{
    background-color: transparent;
    border: none;
}

.contactbutton .getintouch{
    margin-left: 0px;
    text-decoration-thickness:2px;
    transition: margin-left 0.5s, text-decoration-thickness 0.5s;
}

.contactbutton:hover{
    cursor: pointer;
}

.contactbutton:hover .getintouch{
    margin-left: 10px;
    text-decoration-thickness:1.2px;
    transition: margin-left 0.75s, text-decoration-thickness 0.75s;
}

a:hover div{
    opacity: 1;
    transition: opacity 0.3s
}

.hoverContainer{
    position: absolute;
    top:-0.75em;
    left:2vw;
    color: var(--dark_gray);
    font-family: 'Lato', sans-serif;
    font-size: 0.75em;
    background-color: white;
    padding: 2px 5px;
    border: 1px solid var(--dark_gray);
    border-radius: 3.5px;
    opacity: 0;
}

.flexright{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 10%;
    right: 7.5vw;
    opacity: 0;
    animation: textslidein 1s forwards 1.4s;
    cursor: pointer;
}

.flexright:hover{
    cursor: pointer;
}

.flexright:hover .scroll{
    background: linear-gradient(90deg, #e0e0e0, #999999,#424242, #999999,#e0e0e0);
    background-repeat: repeat;
    animation: shining 5s linear infinite;
    background-size: 300% 100%;
    background-clip: text; 
    color: transparent;
}

.scroll{
    font: 500 2.75vmin 'Lato';

}

.arrowdown{
    height: 3vmin;
    margin-left: 0.5vw;
    margin-top: 0px;
    animation: bounce 2s ease-in-out infinite;
    transition: fill 2.5s;
    transition-delay: 0.1s;
}

@keyframes bounce {
    0% {
        margin-top: -5px;
    }
    30%{
        margin-top: 8px;
    }
    90%,100%{
        margin-top: -5px;
    }
}