.archive-date{
    width: 20%;
    font: 400 2.5vmin 'Lato';
}

.archive-name{
    width: 30%;
    font: 400 2.5vmin 'Lato';
}

.archive-company{
    width: 20%;
    font: 400 2.5vmin 'Lato';
}

.archive-scope{
    width: 30%;
    font: 400 2.5vmin 'Lato';
}
